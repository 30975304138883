import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import Accolades from "../components/Repeating/Accolades";
import CallToAction from "../components/Repeating/CTA";
import { mapEdgesToNodes } from "../lib/helpers";
import ButtonSolid from "../components/Button/ButtonSolid";
// import styled from "@emotion/styled";
// import tw from "twin.macro";

// const StyledHero = styled(BgImage)`
//   &:before,
//   &:after {
//     ${({ mobileRemoveBackground }) =>
//       mobileRemoveBackground && tw`!hidden md:!block`};
//   }
// `;

const Page = ({ data }) => {
  const shareholderAttorneysNodes = (data || {}).shareholderAttorneys
    ? mapEdgesToNodes(data.shareholderAttorneys)
    : [];
  const seniorAssociatesNodes = (data || {}).seniorAssociates
    ? mapEdgesToNodes(data.seniorAssociates)
    : [];
  const associateAttorneysNodes = (data || {}).associateAttorneys
    ? mapEdgesToNodes(data.associateAttorneys)
    : [];
  const paralegalsNodes = (data || {}).paralegals ? mapEdgesToNodes(data.paralegals) : [];
  const lawClerksNodes = (data || {}).lawClerks ? mapEdgesToNodes(data.lawClerks) : [];
  const staffNodes = (data || {}).staff ? mapEdgesToNodes(data.staff) : [];
  const managementNodes = (data || {}).management ? mapEdgesToNodes(data.management) : [];
  const heroImage = data.hero.childImageSharp.gatsbyImageData;
  const pluginImage = getImage(heroImage);
  const heroImageMobile = data.heroMobile.childImageSharp.gatsbyImageData;
  const pluginImageMobile = getImage(heroImageMobile);

  return (
    <Layout>
      <SearchEngineOptimization
        title="Our Team | Colorado Lawyer | JBP"
        description="Meet our team of Colorado attorneys at Jorgensen, Brownell & Pepin. We practice personal injury, criminal defense, family law, business litigation, and more!"
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />
      {/* DESKTOP HERO */}
      <section className="pb-[24rem] hidden md:block md:pb-44 md:min-h-[550px]">
        <BgImage
          className="h-full"
          image={pluginImage}
          style={{ minWidth: "100%", minHeight: 550 }}
        >
          <div className="container pt-5">
            <div className="relative pb-[55px] h-full">
              <div className=" top-[290px] absolute ">
                <div className="bg-gray-900 text-gray-300 uppercase px-2.5 py-1.5 inline-flex items-center justify-center mb-5 md:mb-6">
                  Since 1989
                </div>
                <h1>Who We Are</h1>
                <div className="md:pl-8 lg:pl-14 md:pt-3.5">
                  <p className="mb-8">
                    At Jorgensen, Brownell & Pepin, P.C., we’re proud to have assembled a group of
                    dedicated and passionate Colorado lawyers. For over 30 years, we’ve delivered
                    legal services of the highest caliber. Whether we’re addressing a personal
                    injury case, resolving a family law dispute, working on a construction defect
                    claim, assisting with a real estate dispute, or creating a solid estate plan, we
                    are strategic with our approach.
                  </p>
                  <ButtonSolid
                    modal="modal-contact"
                    text="Contact Us"
                    className="w-full md:w-auto"
                  />
                </div>
              </div>
            </div>
          </div>
        </BgImage>
      </section>
      {/* END OF DESKTOP HERO */}
      {/* MOBILE HERO */}
      <section className="md:hidden pb-20 md:pb-44 md:min-h-[550px]">
        <BgImage
          className="h-full"
          image={pluginImageMobile}
          style={{ minWidth: "100%", minHeight: 550 }}
        ></BgImage>
        <div className="container pt-5">
          <div className="relative h-full">
            <div className=" ">
              <div className="bg-gray-900 text-gray-300 uppercase px-2.5 py-1.5 inline-flex items-center justify-center mb-5 md:mb-6">
                Since 1989
              </div>
              <p className="heading-one">Who We Are</p>
              <div className="md:pl-8 lg:pl-14 md:pt-3.5">
                <p className="mb-8">
                  At Jorgensen, Brownell & Pepin, P.C., we’re proud to have assembled a group of
                  dedicated and passionate Colorado lawyers. For over 30 years, we’ve delivered
                  legal services of the highest caliber. Whether we’re addressing a personal injury
                  case, resolving a family law dispute, working on a construction defect claim,
                  assisting with a real estate dispute, or creating a solid estate plan, we are
                  strategic with our approach.
                </p>
                <ButtonSolid modal="modal-contact" text="Contact Us" className="w-full md:w-auto" />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* END OF MOBILE HERO */}

      <Accolades className="!pb-14" />

      <section className="pb-4">
        <div className="container">
          <header className="mb-8">
            <h2>Meet Our Devoted Team</h2>
          </header>

          {/* <AniLink fade to="/our-team/tiffanee-trujillo/" className="flex items-center space-x-2.5 mb-8">
                  <StaticImage src="../images/Our Team/Tiffanee.png" width="133" />
                  <div className="italic text-lg md:text-xl text-secondary-50 font-medium font-heading">In Memoriam - Tiffanee Trujillo</div>
               </AniLink> */}

          {shareholderAttorneysNodes.length > 0 && (
            <>
              <header className="mb-12">
                <h3>Shareholder Attorneys</h3>
              </header>
              <div className="grid md:grid-cols-3 lg:grid-cols-4 gap-y-8 md:gap-y-12 md:gap-x-6 mb-16 md:mb-26">
                {shareholderAttorneysNodes.map((item, i) => (
                  <div key={i}>
                    <AniLink
                      fade
                      to={`/our-team/${item.slug.current}/`}
                      className="group no-underline"
                    >
                      <div className="overflow-hidden mb-3.5">
                        <GatsbyImage
                          image={item.headshot.asset.gatsbyImageData}
                          className="w-full transform scale-100 md:group-hover:scale-105 filter blur-none transition-all duration-500 ease-linear"
                        />
                      </div>
                      <div className="font-heading text-secondary-50 text-lg md:text-xl mb-0">
                        {item.name}
                      </div>
                      <div className="text-primary-50 text-sm mb-0">{item.title}</div>
                      {/* <div className="text-primary-50 text-sm mb-0 font-bold">{item.isRetired ? "Retired": ""}</div> */}
                    </AniLink>
                  </div>
                ))}
              </div>
            </>
          )}

          {seniorAssociatesNodes.length > 0 && (
            <>
              <header className="mb-12">
                <h3>Senior Associates</h3>
              </header>
              <div className="grid md:grid-cols-3 lg:grid-cols-4 gap-y-8 md:gap-y-12 md:gap-x-6 mb-16 md:mb-26">
                {seniorAssociatesNodes.map((item, i) => (
                  <div key={i}>
                    <AniLink
                      fade
                      to={`/our-team/${item.slug.current}/`}
                      className="group no-underline"
                    >
                      <div className="overflow-hidden mb-3.5">
                        <GatsbyImage
                          image={item.headshot.asset.gatsbyImageData}
                          className="w-full transform scale-100 md:group-hover:scale-105 filter blur-none transition-all duration-500 ease-linear"
                        />
                      </div>
                      <div className="font-heading text-secondary-50 text-lg md:text-xl mb-0">
                        {item.name}
                      </div>
                      <div className="text-primary-50 text-sm mb-0">{item.title}</div>
                    </AniLink>
                  </div>
                ))}
              </div>
            </>
          )}

          {associateAttorneysNodes.length > 0 && (
            <>
              <header className="mb-12">
                <h3>Associate Attorneys</h3>
              </header>
              <div className="grid md:grid-cols-3 lg:grid-cols-4 gap-y-8 md:gap-y-12 md:gap-x-6 mb-16 md:mb-26">
                {associateAttorneysNodes.map((item, i) => (
                  <div key={i}>
                    <AniLink
                      fade
                      to={`/our-team/${item.slug.current}/`}
                      className="group no-underline"
                    >
                      <div className="overflow-hidden mb-3.5">
                        <GatsbyImage
                          image={item.headshot.asset.gatsbyImageData}
                          className="w-full transform scale-100 md:group-hover:scale-105 filter blur-none transition-all duration-500 ease-linear"
                        />
                      </div>
                      <div className="font-heading text-secondary-50 text-lg md:text-xl mb-0">
                        {item.name}
                      </div>
                      <div className="text-primary-50 text-sm mb-0">{item.title}</div>
                    </AniLink>
                  </div>
                ))}
              </div>
            </>
          )}

          {lawClerksNodes.length > 0 && (
            <>
              <header className="mb-12">
                <h3>Law Clerks</h3>
              </header>
              <div className="grid md:grid-cols-3 lg:grid-cols-4 gap-y-8 md:gap-y-12 md:gap-x-6 mb-16 md:mb-26">
                {lawClerksNodes.map((item, i) => (
                  <div key={i}>
                    <AniLink
                      fade
                      to={`/our-team/${item.slug.current}/`}
                      className="group no-underline"
                    >
                      <div className="overflow-hidden mb-3.5">
                        <GatsbyImage
                          image={item.headshot.asset.gatsbyImageData}
                          className="w-full transform scale-100 md:group-hover:scale-105 filter blur-none transition-all duration-500 ease-linear"
                        />
                      </div>
                      <div className="font-heading text-secondary-50 text-lg md:text-xl mb-0">
                        {item.name}
                      </div>
                      <div className="text-primary-50 text-sm mb-0">{item.title}</div>
                    </AniLink>
                  </div>
                ))}
              </div>
            </>
          )}

          {paralegalsNodes.length > 0 && (
            <>
              <header className="mb-12">
                <h3>Paralegals</h3>
              </header>
              <div className="grid md:grid-cols-3 lg:grid-cols-4 gap-y-8 md:gap-y-12 md:gap-x-6 mb-16 md:mb-26">
                {paralegalsNodes.map((item, i) => (
                  <div key={i}>
                    <AniLink
                      fade
                      to={`/our-team/${item.slug.current}/`}
                      className="group no-underline"
                    >
                      <div className="overflow-hidden mb-3.5">
                        {item.name === "Tiffanee Trujillo" ? (
                          <StaticImage
                            src="../images/Our Team/Tiffanee Trujillo - blackout.jpg"
                            width="564"
                            className="w-full transform scale-100 md:group-hover:scale-105 filter blur-none transition-all duration-500 ease-linear"
                          />
                        ) : (
                          <GatsbyImage
                            image={item.headshot.asset.gatsbyImageData}
                            className="w-full transform scale-100 md:group-hover:scale-105 filter blur-none transition-all duration-500 ease-linear"
                          />
                        )}
                      </div>
                      {item.name === "Tiffanee Trujillo" ? (
                        <div className="font-heading text-secondary-50 text-lg md:text-xl mb-0">
                          <span className="font-heading italic font-medium text-lg">
                            In Memoriam -
                          </span>{" "}
                          {item.name}
                        </div>
                      ) : (
                        <div className="font-heading text-secondary-50 text-lg md:text-xl mb-0">
                          {item.name}
                        </div>
                      )}

                      <div className="text-primary-50 text-sm mb-0">{item.title}</div>
                    </AniLink>
                  </div>
                ))}
              </div>
            </>
          )}

          {managementNodes.length > 0 && (
            <>
              <header className="mb-12">
                <h3>Management Team</h3>
              </header>
              <div className="grid md:grid-cols-3 lg:grid-cols-4 gap-y-8 md:gap-y-12 md:gap-x-6 mb-16 md:mb-26">
                {managementNodes.map((item, i) => (
                  <div key={i}>
                    <AniLink
                      fade
                      to={`/our-team/${item.slug.current}/`}
                      className="group no-underline"
                    >
                      <div className="overflow-hidden mb-3.5">
                        <GatsbyImage
                          image={item.headshot.asset.gatsbyImageData}
                          className="w-full transform scale-100 md:group-hover:scale-105 filter blur-none transition-all duration-500 ease-linear"
                        />
                      </div>
                      <div className="font-heading text-secondary-50 text-lg md:text-xl mb-0">
                        {item.name}
                      </div>
                      <div className="text-primary-50 text-sm mb-0">
                        {item.title.split("/").map((item) => (
                          <>
                            {" "}
                            {item} <br />{" "}
                          </>
                        ))}
                      </div>
                    </AniLink>
                  </div>
                ))}
              </div>
            </>
          )}

          {staffNodes.length > 0 && (
            <>
              <header className="mb-12">
                <h3>Support Staff</h3>
              </header>
              <div className="grid md:grid-cols-3 lg:grid-cols-4 gap-y-8 md:gap-y-12 md:gap-x-6 mb-16 md:mb-26">
                {staffNodes.map((item, i) => (
                  <div key={i}>
                    <AniLink
                      fade
                      to={`/our-team/${item.slug.current}/`}
                      className="group no-underline"
                    >
                      <div className="overflow-hidden mb-3.5">
                        <GatsbyImage
                          image={item.headshot.asset.gatsbyImageData}
                          className="w-full transform scale-100 md:group-hover:scale-105 filter blur-none transition-all duration-500 ease-linear"
                        />
                      </div>
                      <div className="font-heading text-secondary-50 text-lg md:text-xl mb-0">
                        {item.name}
                      </div>
                      <div className="text-primary-50 text-sm mb-0">
                        {item.title.split("/").map((item) => (
                          <>
                            {" "}
                            {item} <br />{" "}
                          </>
                        ))}
                      </div>
                    </AniLink>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </section>
      <CallToAction />
    </Layout>
  );
};

export const data = graphql`
  {
    openGraphImage: file(relativePath: { eq: "open-graph/facebook/About.jpg" }) {
      publicURL
    }
    twitterOpenGraphImage: file(relativePath: { eq: "open-graph/twitter/About.jpg" }) {
      publicURL
    }
    hero: file(relativePath: { eq: "Our Team/firm/about-page_hero-fullpage.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    heroMobile: file(relativePath: { eq: "Our Team/firm/team-members-full.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    shareholderAttorneys: allSanityTeamMember(
      filter: { categories: { elemMatch: { title: { in: "Shareholder Attorneys" } } } }
      sort: { fields: [order], order: ASC }
    ) {
      edges {
        node {
          id
          name
          slug {
            current
          }
          seoTitle
          metaDescription
          headshot {
            asset {
              gatsbyImageData(layout: CONSTRAINED, width: 564)
            }
          }
          title
          isRetired
          categories {
            title
          }
        }
      }
    }
    seniorAssociates: allSanityTeamMember(
      filter: { categories: { elemMatch: { title: { in: "Senior Associates" } } } }
      sort: { fields: [order], order: ASC }
    ) {
      edges {
        node {
          id
          name
          slug {
            current
          }
          seoTitle
          metaDescription
          headshot {
            asset {
              gatsbyImageData(layout: CONSTRAINED, width: 564)
            }
          }
          title
          categories {
            title
          }
        }
      }
    }
    associateAttorneys: allSanityTeamMember(
      filter: { categories: { elemMatch: { title: { in: "Associate Attorneys" } } } }
      sort: { fields: [order], order: ASC }
    ) {
      edges {
        node {
          id
          name
          slug {
            current
          }
          seoTitle
          metaDescription
          headshot {
            asset {
              gatsbyImageData(layout: CONSTRAINED, width: 564)
            }
          }
          title
          categories {
            title
          }
        }
      }
    }
    paralegals: allSanityTeamMember(
      filter: { categories: { elemMatch: { title: { in: "Paralegals" } } } }
      sort: { fields: [order], order: ASC }
    ) {
      edges {
        node {
          id
          name
          slug {
            current
          }
          seoTitle
          metaDescription
          headshot {
            asset {
              gatsbyImageData(layout: CONSTRAINED, width: 564)
            }
          }
          title
          categories {
            title
          }
        }
      }
    }
    lawClerks: allSanityTeamMember(
      filter: { categories: { elemMatch: { title: { in: "Law Clerks" } } } }
      sort: { fields: [order], order: ASC }
    ) {
      edges {
        node {
          id
          name
          slug {
            current
          }
          seoTitle
          metaDescription
          headshot {
            asset {
              gatsbyImageData(layout: CONSTRAINED, width: 564)
            }
          }
          title
          categories {
            title
          }
        }
      }
    }
    staff: allSanityTeamMember(
      filter: { categories: { elemMatch: { title: { in: "Staff" } } } }
      sort: { fields: [order], order: ASC }
    ) {
      edges {
        node {
          id
          name
          slug {
            current
          }
          seoTitle
          metaDescription
          headshot {
            asset {
              gatsbyImageData(layout: CONSTRAINED, width: 564)
            }
          }
          title
          categories {
            title
          }
        }
      }
    }
    management: allSanityTeamMember(
      filter: { categories: { elemMatch: { title: { in: "Management" } } } }
      sort: { fields: [order], order: ASC }
    ) {
      edges {
        node {
          id
          name
          slug {
            current
          }
          seoTitle
          metaDescription
          headshot {
            asset {
              gatsbyImageData(layout: CONSTRAINED, width: 564)
            }
          }
          title
          categories {
            title
          }
        }
      }
    }
  }
`;

export default Page;
